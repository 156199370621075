import React, { useMemo } from 'react'
import compact from 'just-compact'
import { CommunityTake } from '@heights/heights-ui-components'
import { withErrorBoundary } from '@/utils'
import { transformImage } from '@/utils/contentful'
import { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { testimonial, testimonialSection } from '@/schemas'
import { useData } from '@/hooks'
import { DataSourceType } from '@/hooks/useData'
import { useTransformContentfulCtaAction } from '@/hooks/useCtaAction'

type Props = {
  data: SingleComponentPickerItem
}

const [testimonialQuery, testimonialType] = testimonial.builder
  .filter('_id == ^._ref')
  .map((testimonial) => ({
    image: testimonial.fields.image?.resolve(['_id', 'fields']).use(),
    mobileImage: testimonial.fields.mobileImage
      ?.resolve(['_id', 'fields'])
      .use(),
    authorImage: testimonial.fields.authorImage
      ?.resolve(['_id', 'fields'])
      .use(),
    callToAction: testimonial.fields.callToAction
      ?.resolve(['_id', 'fields'])
      .use(),
  }))
  .first()
  .use()

const [query, type] = testimonialSection.builder
  .filter('_id == $id')
  .map((section) => ({
    backgroundImage: section.fields.backgroundImage
      ?.resolve(['_id', 'fields'])
      .use(),
  }))
  .subquery({
    testimonials: [
      `fields.testimonials[]{
        "testimonial": ${testimonialQuery}
      }`,
      [],
    ] as [
      string,
      (
        | { testimonial: typeof testimonialType | null | undefined }[]
        | null
        | undefined
      )
    ],
  })
  .first()
  .use()

export const ContentfulTestimonialSection: React.FunctionComponent<Props> =
  withErrorBoundary(({ data }) => {
    const transformCta = useTransformContentfulCtaAction()
    const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, {
      id: data._id,
    })
    const section = reader()

    const takes = useMemo(
      () =>
        compact(
          section?.testimonials?.map(({ testimonial }) => {
            const action = transformCta(testimonial?.callToAction)
            const image = transformImage(testimonial?.image?.fields, {
              width: 1000,
            })
            const mobileImage = transformImage(
              testimonial?.mobileImage?.fields,
              { width: 1000 }
            )
            const authorImage = transformImage(
              testimonial?.authorImage?.fields,
              { width: 1000 }
            )
            if (testimonial && image) {
              return {
                image,
                mobileImage: mobileImage ?? undefined,
                quote: testimonial.fields?.quote ?? '',
                author: testimonial.fields?.name ?? '',
                authorTitle: testimonial.fields?.description ?? '',
                authorImage: authorImage ?? undefined,
                icon: testimonial.fields?.icon,
                imagePlacement:
                  testimonial.fields?.imagePlacement === 'right'
                    ? ('end' as const)
                    : ('start' as const),
                callToAction: action ?? undefined,
              }
            }

            return null
          }) ?? []
        ),
      [section, transformCta]
    )

    return (
      <CommunityTake
        title={section?.fields?.title ?? ''}
        takes={takes}
        backgroundColor={section?.fields?.backgroundColor}
        backgroundImage={
          transformImage(section?.backgroundImage?.fields, {
            width: 1920,
            fullscreen: true,
          }) ?? undefined
        }
      />
    )
  })

export default ContentfulTestimonialSection
