import { SocialPlatformIcons } from '@/constants/SocialPlatformIcons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { ctaAction } from './ctaAction'

const testimonialFields = defineObject(
  'testimonialFields',
  {
    name: { type: 'string' },
    description: { type: 'string' },
    quote: { type: 'string' },
    icon: {
      type: 'string',
      options: { list: Object.values(SocialPlatformIcons) },
    },
    image: { type: 'reference', to: [{ type: 'asset' }] },
    mobileImage: { type: 'reference', to: [{ type: 'asset' }] },
    authorImage: { type: 'reference', to: [{ type: 'asset' }] },
    imagePlacement: {
      type: 'string',
      options: { list: ['left' as const, 'right' as const] },
    },
    callToAction: {
      type: 'reference',
      to: [{ type: 'ctaAction' }],
    },
  },
  [asset.asset, ctaAction.ctaAction]
)

export const testimonial = defineDocument(
  'testimonial',
  {
    fields: { type: 'testimonialFields' },
  },
  [testimonialFields.testimonialFields]
)
