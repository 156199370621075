import { backgroundColors } from '@/contentful/utils/commonValues'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { testimonial } from './testimonial'

const testimonialSectionFields = defineObject(
  'testimonialSectionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    title: { type: 'string' },
    testimonials: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'testimonial' }] }],
    },
    backgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
  },
  [asset.asset, testimonial.testimonial, asset.asset]
)

export const testimonialSection = defineDocument(
  'testimonialSection',
  {
    fields: { type: 'testimonialSectionFields' },
  },
  [testimonialSectionFields.testimonialSectionFields]
)
